import ApiService from "./ApiService";

export const getOnePageReportGrupo = async (codGrupo: number, ano, mes, codMarca?: string): Promise<any> => {
    const query = codMarca ? `/onePageMarca/${codGrupo}/${codMarca}/${ano}/${mes}` : `/onePageGrupo/${codGrupo}/${ano}/${mes}`;
    const { data } = await ApiService.get(query);
    
    return data;
};

export const getPdfMarca = async (distribuidor: number, codGrupo, codMarca, ano, mes): Promise<any> => {
    const { data } = await ApiService.get(`/pdfPorMarca/${distribuidor}/${codGrupo}/${codMarca}/${ano}/${mes}`);
    return data;
};

export const getGrupos = async (): Promise<any> => {
    const { data } = await ApiService.get(`/gruposOptions`);
    return data;
};

export const getMarcas = async (codGrupo: number): Promise<any> => {
    const { data } = await ApiService.get(`/marcasOptions/${codGrupo}`);
    return data;
};

export const getPdfgrupo = async (distribuidor: number, codGrupo, ano, mes): Promise<any> => {
    const { data } = await ApiService.get(`/pdfPorGrupo/${distribuidor}/${codGrupo}/${ano}/${mes}`);
    return data;
};

export const exportXlsxMarca = async (distribuidor: number, codGrupo, ano, mes, codMarca): Promise<any> => {
    const { data } = await ApiService.postXlsx(`/exportXlsxMarca/${distribuidor}/${codGrupo}/${ano}/${mes}/${codMarca}`);  
    return data;
};

export const exportXlsxMarcaGrupo = async (distribuidor: number, codGrupo, ano, mes): Promise<any> => {
    const { data } = await ApiService.postXlsx(`/exportXlsxMarcaGrupo/${distribuidor}/${codGrupo}/${ano}/${mes}`);  
    return data;
};
